import React, { FC, useEffect } from 'react'
import Head from 'next/head'

const Index: FC = () => {
  
  useEffect(() => {
    window.location.replace('en')
  })
  
  return (
    <Head>
      <meta name='robots' content='noindex, nofollow' />
    </Head>
  )
}

export default Index
